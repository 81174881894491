<template>
  <div class="date-picker-container" :class="{'bordered': bordered}">
    <datepicker
      v-model="dateModel"
      :locale="dateLocale"
      :input-format="'dd MMMM yyyy'"
      :placeholder="bordered? label: ''"
      :upper-limit="upperLimit"
      :lower-limit="lowerLimit"
      :clearable="clearable"
      @click="manageBar('open')"
      @blur="manageBar('close')"
    />
    <span v-if="!bordered" class="bar" :class="{ active: (required && !dateModel) || bar }" />
    <label v-if="!bordered" :for="id" :class="{'placeholder-label': isLabelTop}">
      {{ label }}
      <span v-if="required" class="pin">*</span>
    </label>
  </div>
</template>

<script>

import Datepicker from 'vue3-datepicker'
import it from 'date-fns/locale/it'
import { toRefs, computed, reactive } from 'vue'

export default {
  components: {
    Datepicker
  },
  props: {
    clearable: {
      type: Boolean,
      default: () => { return false }
    },
    date: {
      type: Date || String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: () => { return false }
    },
    bordered: {
      type: Boolean,
      default: () => { return false }
    },
    upperLimit: {
      type: Date || null || undefined,
      default: () => { return null }
    },
    lowerLimit: {
      type: Date || null || undefined,
      default: () => { return null }
    }
  },
  emits: ['update:date'],
  setup (props, { emit }) {
    const state = reactive({
      bar: false,
      dateLocale: it
    })
   
    const dateModel = computed({
      get: () => {
        return props.date
      },
      set: (value) => {
        state.bar = false
        emit('update:date', value)
      }
    })
    const isLabelTop = computed(() => {
      return typeof dateModel.value === 'object'
    })
    const manageBar = (mode) => {
      if(mode === 'open') {
        state.bar = true
      } else {
        state.bar = false
      }
    }
    return {
      ...toRefs(state),
      dateModel,
      manageBar,
      isLabelTop
    }
  }
}
</script>

<style lang="scss">

.date-picker-container{
  position: relative;
  width:100%;
  margin-top: 31px;
  height: 34px;
  .v3dp__popout {
    z-index: 9999;
    border:1px solid #17a2b8;
    backdrop-filter: blur(20px);
    padding:0;
    .v3dp__subheading {
      margin: .6rem 0!important
    }
  }
  .v3dp__datepicker {
    height: 34px;
  }
  .v3dp__datepicker .v3dp__input_wrapper input {
    width:100%;
    background: transparent;
    color:white;
    border-top:0;
    border-left:0;
    border-right:0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.65);
    outline:0;
    &:focus,
    &:focus-visible {
    border-top:0;
    border-left:0;
    border-right:0;
    outline:0;
    }
  }
  span.bar {
		position: relative;
		display: block;
		width: 100%;
		margin-top: 1px;

		&:before {
			left: 50%;
		}

		&:after {
			right: 50%;
		}

		&:after,
		&:before {
			content: '';
			height: 2px;
			width: 0;
			bottom: 1px;
			position: absolute;
			background: var(--accented);
			transition: 0.2s ease all;
		}
	}

	label {
		font-weight: normal;
		position: absolute;
		pointer-events: none;
		transition: 0.2s ease all;
		top: -4px;
		font-size: 14px;
		left: 2px;
		color: var(--accented);
		transition: top .5s ease-in-out;
	}
	.placeholder-label{
		top: -27px!important;
		transition: top .1s ease-in;
	}
  span.bar.active {
					&:before {
						width: 50%!important;
					}
					&:after {
						width: 50%!important;
					}
				}
}

.bordered {
  margin:0!important;
  .v3dp__popout{
    width:100%!important;
  }
  .v3dp__datepicker {
    height: 34px!important;
    border: 1px solid hsla(0,0%,100%,.5);
    box-shadow: none!important;
    outline: none!important;
    border-radius:4px!important;

    .v3dp__input_wrapper {
      display: flex;

      .v3dp__clearable {
      top: 10px;
    }
    }

    .v3dp__elements button:disabled {
      opacity: .65;
      color: #b4c9ec;
    }
    input {
      border:0!important;
      height: 34px!important;
      padding: 0 15px!important;
      font-size:14px!important;
      font-family: 'Lato';
      &::placeholder {
        color:hsla(0,0%,100%,.70);;
        font-size:14px!important;
        font-family: 'Lato';
      }
    }
  }
}
</style>