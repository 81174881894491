<template>
  <div id="mySidebar" class="sidebar" @keydown.enter.prevent="">
    <div href="/" aria-current="page" class="logo" @click="toggleSidebar()">
      <img v-if="sidebar.opened" src="../assets/cving.full.svg" alt="CVing">
      <img v-else src="../assets/cving.circle.svg" alt="CVing">
    </div>

    <div v-for="(section, i) in getSections" :key="'s' + i">
      <div v-if="section.expandable" class="main-tab" :class="{ '--tab-open': openSections.indexOf(section.name) != -1 }" :style="!sidebar.opened ? 'justify-content:center' : ''" @click="manageOpen(section.name)">
        <span class="light" />
        <font-awesome-icon :icon="section.icon" class="fa-xl" style="min-width: 40px" />
        <span v-if="sidebar.opened">{{ section.name }}</span>
        <font-awesome-icon v-if="sidebar.opened" icon="angle-down" class="on-right fa-sm" style="width: 20px!important;height:15px!important" />
      </div>
      <div v-else-if="getLinks.find(e => e.section === section.name)" class="main-tab" :class="{ '--tab-open': openSections.indexOf(section.name) != -1 }" :style="!sidebar.opened ? 'justify-content:center' : ''" @click="manageOpen(getLinks.find(e => e.section === section.name).name)">
        <router-link :to="getLinks.find(e => e.section === section.name).path">
          <div class="r-link">
            <span class="light" />
            <font-awesome-icon :icon="section.icon" class="fa-xl" style="min-width: 40px" />
            <span v-if="sidebar.opened"> {{ getLinks.find(e => e.section === section.name).title }} </span>
          </div>
        </router-link>
      </div>
      <div v-if="section.expandable" class="expand-tab" :style="(openSections.indexOf(section.name) != -1) ? 'height:' + calculateHeight(getLinks.filter(e => e.section === section.name && !e.hideInProduction).length) + 'px' : ''">
        <div v-for="(link, i) in getLinks.filter((l) => l.section === section.name)" :key="'m' + i" class="link-tab" :class="{ 'no-padding': !sidebar.opened, 'hidden': link.hideInProduction }">
          <router-link :to="link.path">
            <div :style="!sidebar.opened ? 'width:100%;text-align:center' : ''">
              <span v-if="sidebar.opened">{{ link.title }}</span>
              <span v-else>
                {{ link.shortTitle ?? link.title }}
              </span>
              <span v-if="link.quantity && getQuantity > 0" class="little-badge">{{ getQuantity }}</span>
              <span v-if="link.payments && getPayments > 0" class="little-badge">{{ getPayments }}</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sidebarStore } from '@/stores/sidebar'
import { onMounted, toRefs, reactive, computed, watch } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
import { sbapibackoffice } from '@/api'
import { permissionsStore } from '@/stores/permissions'
import { ref } from 'vue'

export default {
  setup() {
    const permissions = permissionsStore()
    const sidebar = sidebarStore()
    const state = reactive({
      payments: 0,
      quantity: 0,
      show: false,
      multiple: false,
      sections: [
        { name: 'Aziende', icon: 'building', expandable: true },
        { name: 'Candidati', icon: 'users', expandable: true },
        { name: 'Contabilità', icon: 'credit-card', expandable: true },
        // { name: 'Shop', icon: 'check-to-slot' }
        { name: 'Logs', icon: 'check-to-slot', expandable: false },
        { name: 'Marketing', icon: 'bullhorn', expandable: false },
        { name: 'Progetti', icon: 'tag', expandable: true },
        { name: 'Permessi', icon: 'lock', expandable: false },
        { name: 'NoWhere Indeed Umana', icon: 'file-csv', expandable: true }
        // { name: 'Sales', icon: 'money-bills', expandable: true }
      ],
      openSections: [''],
      uniqueSections: []
    })
    const getSections = computed(() => {
      permissions.refresh()
      const filtered = []

      state.sections.forEach((section) => {
        if (getLinks.value.find((e) => e.section === section.name)) {
          filtered.push(section)
        }
      })

      return filtered
    })
    const links = ref([
      {
        path: '/referrals',
        title: 'Nuovi suggeriti',
        privilege: 'ANONYMIZE_CANDIDATE_VIEW',
        shortTitle: 'Suggeriti',
        section: 'Candidati',
        quantity: true
      },
      // {
      //   path: '/suggestors',
      //   title: 'Suggeritori',
      //   privilege: 'ANONYMIZE_CANDIDATE_VIEW',
      //   section: 'Candidati'
      // },
      {
        path: '/billingDocuments',
        title: 'Documenti riconoscimento',
        privilege: 'DATA_INVOICE_VIEW',
        shortTitle: 'Documenti',
        section: 'Candidati'
      },
      {
        path: '/checkPayments',
        title: 'Richieste pagamento',
        privilege: 'PAYOUT_REQUEST_VIEW',
        shortTitle: 'Richieste',
        section: 'Candidati',
        payments: true
      },
      {
        path: '/archive',
        title: 'Candidati anonimizzati',
        privilege: 'SUGGESTED_CANDIDATE_VIEW',
        shortTitle: 'Anonimizzati',
        section: 'Candidati'
      },
      {
        path: '/checkCandidate',
        title: 'Gestione candidati',
        privilege: 'CANDIDATE_VIEW',
        shortTitle: 'Candidati',
        section: 'Candidati'
      },
      {
        path: '/companiesList',
        title: 'Tutte le aziende',
        shortTitle: 'Aziende',
        privilege: 'COMPANY_VIEW',
        section: 'Aziende'
      },
      {
        path: '/newCompany',
        title: 'Crea azienda',
        shortTitle: 'Crea',
        privilege: 'COMPANY_CREATE',
        section: 'Aziende'
      },
      {
        path: '/coupons',
        title: 'Codici sconto',
        shortTitle: 'Codici',
        privilege: 'DISCOUNT_CODE_VIEW',
        section: 'Aziende'
      },

      {
        path: '/accounting/companies',
        title: 'Aziende',
        privilege: 'COMPANY_MONEY_TRANSACTION_UNLOCKING',
        section: 'Contabilità'
      },
      {
        path: '/accounting/unlockingActions',
        title: 'Sblocchi',
        privilege: 'COMPANY_UNLOCKING_VIEW',
        section: 'Contabilità'
      },
      {
        path: '/accounting/references',
        title: 'Referenziatori',
        privilege: 'COMPANY_REFERRAL_PAYOUT_VIEW',
        section: 'Contabilità'
      },
      {
        path: '/accounting/report',
        title: 'Annunci aggregati',
        privilege: 'SALES_POST_VIEW',
        shortTitle: 'Aggregati',
        section: 'Sales'
      },
      {
        path: '/logs',
        privilege: 'EVENTS_VIEW',
        title: 'Logs',
        section: 'Logs'
      },
      {
        path: '/jobAdvertisement',
        title: 'Verifica annunci',
        shortTitle: 'Verifica',
        privilege: 'EVENTS_VIEW',
        section: 'Aziende'
      },
      // {
      //   path: '/createProject',
      //   title: 'Crea progetto',
      //   section: 'Progetti'
      // },
      {
        path: '/createProjectUnique',
        title: 'Crea progetto',
        section: 'Progetti'
      },
      {
        path: '/allProjects',
        title: 'Tutti i progetti',
        section: 'Progetti'
      },
      {
        path: '/marketing',
        title: ' Marketing',
        section: 'Marketing'
      },
      /* {
        path: '/marketing-umana',
        title: 'Liste csv Umana',
        section: 'Marketing'
      }, */
      // {
      //   path: '/shares',
      //   title: 'Condivisioni',
      //   section: 'Aziende'
      // }
      {
        path: '/permissions',
        title: 'Permessi',
        privilege: 'PERMISSION_VIEW',
        section: 'Permessi'
      },
      {
        path: '/nowhereindeedumana',
        title: ' Feed Indeed NoWhere',
        section: 'NoWhere Indeed Umana',
        privilege: 'INDEED_FEED_MANAGEMENT'
      },
      {
        path: '/jobboards',
        title: 'Blacklist Indeed',
        section: 'NoWhere Indeed Umana',
        privilege: 'BANNED_KEYWORDS'
      }
    ])

    const toggleSidebar = () => {
      sidebar.toggle()
    }

    // on window resize, close sidebar if opened
    window.addEventListener('resize', () => {
      if (localStorage.getItem('userId')) {
        if (window.innerWidth < 992) sidebar.close()
        else sidebar.open()
      }
    })

    const getLinks = computed(() => {
      const toRtn = []

      for (const link of links.value) {
        if (permissions.canUse(link.privilege)) toRtn.push(link)
      }

      return toRtn
    })

    const route = useRoute()
    watch(route, (to) => {
      const link = links.value.find(link => link.path === to.fullPath)
      if (link && to.fullPath !== '/' && !state.openSections.includes(link.section)) {
        manageOpen(link.section)
      }
    }, { flush: 'pre', immediate: true, deep: true })
    const callPagamenti = () => {
      sbapibackoffice
        .get('/referrals/payment')
        .then((response) => {
          if (response.data) {
            let count = response.data.filter(p => { return p.state === 'created' && !p.payoutEnabled })
            localStorage.setItem('payments', count.length)
            state.payments = count.length
          }
        })
        .catch(() => { })
    }

    const manageOpen = (sec) => {
      if (sec === 'Pagamenti') {
        callPagamenti()
      }
      if (state.multiple) {
        if (state.openSections.indexOf(sec) === -1) {
          state.openSections.push(sec)
        } else {
          state.openSections.splice(state.openSections.indexOf(sec), 1)
        }
      } else {
        if (state.openSections[0] && state.openSections[0] === sec) {
          state.openSections = []
        } else {
          state.openSections = []
          state.openSections.push(sec)
        }
      }
     
    }
    const calcPx = computed(() => {
      return sidebar.opened ? 40 : 37
    })
    const getQuantity = computed(() => {
      return state.quantity

    })
    const getPayments = computed(() => {
      return state.payments

    })
    onMounted(() => {
      if (localStorage.getItem('userId')) {
        // if the windows is small, collapse the sidebar
        if (window.innerWidth < 992) sidebar.close()
        else sidebar.open()
      }

      state.quantity = localStorage.getItem('referrals') ? localStorage.getItem('referrals') : 0
      state.payments = localStorage.getItem('payments') ? localStorage.getItem('payments') : 0
      window.addEventListener('storage-referrals', (event) => {
        localStorage.setItem('referrals', event.detail.storage)
        state.quantity = event.detail.storage
      })
      window.addEventListener('storage-payments', (event) => {
        if (event.detail.reducePayment) {
          localStorage.setItem('payments', event.detail.reducePayment)
          state.payments = event.detail.reducePayment
        }
      })
    })
    const calculateHeight = (count) => {
      return count * calcPx.value + 15
    }
    return {
      manageOpen,
      sidebar,
      links,
      toggleSidebar,
      ...toRefs(state),
      getSections,
      calcPx,
      getQuantity,
      getPayments,
      calculateHeight,
      getLinks
    }
  }
}
</script>

<style lang="scss">
$small: 300px;

div.expand-tab .link-tab,
#mySidebar .expand-tab a div,
#mySidebar .expand-tab .router-link-active div {
  position: relative;

  .little-badge {
    position: absolute;
    display: inline-block;
    min-width: 1em;
    /* em unit */
    padding: .3em .35em;
    /* em unit */
    border-radius: 50%;
    font-size: 10px;
    text-align: center;
    background: #1779ba;
    color: white !important;
    top: -5px;
    margin-left: 2px;
    min-width: 18px;
  }
}

.sidebar {
  height: 100%;
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  filter: drop-shadow(rgba(255, 255, 255, 0.5) 0.5px 1px 2px);
  //border-right: 2px solid white;
  background: #004074;
  z-index: 99;

  .logo {
    margin-bottom: 20px;
    cursor: pointer;
    text-align: center;

    img {
      height: 45px;
      margin-top: 5px;
      padding: 5px 21px;
    }
  }
}

.router-link-active {
  color: var(--accented) !important;
  text-decoration: none;

  span {
    color: var(--accented) !important;
  }
}

.r-link {
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.link-tab {
  cursor: pointer;
  color: white;
  background: transparent;
  font-size: 15px;
  padding: 10px 0 10px 55px;

  &:hover {
    background: #bcde42;
    background: linear-gradient(90deg,
        rgba(3, 7, 9, 0.2) 40%,
        rgba(41, 94, 120, 0) 100%);
  }

  &.no-padding {
    padding: 10px 0 !important;

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      padding: 0 5px;
    }
  }

  a {
    color: white;
    background: transparent;
    text-decoration: none;
  }
}

a:focus,
a:focus-visible {
  text-decoration: none;
}

.main-tab {
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 0;

  a {
    color: white;
    background: transparent;
    text-decoration: none;
  }

  svg.fa-angle-down {
    transform: rotate(-90deg);
    -webkit-transition: all .35s ease-in;
    -moz-transition: all .35s ease-in;
    -o-transition: all .35s ease-in;
    transition: all .35s ease-in;
  }

  .on-right {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  +.expand-tab {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height .35s ease-in;
    -moz-transition: height .35s ease-in;
    -o-transition: height .35s ease-in;
    transition: height .35s ease-in;

    a {
      color: white;
      text-transform: none;
      text-decoration: none;
    }
  }

  span.light {
    height: 10px;
    width: 10px;
    position: absolute;
    top: 20px;
    left: 16px;
    background-color: transparent;
    border-radius: 100%;
    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  svg {
    width: 18px;
    height: 18px !important;
    margin: 0 5px 0 5px;
    z-index: 999;
  }

  &.--tab-open {
    background: linear-gradient(90deg,
        rgba(66, 186, 222, 0.41) 60%,
        rgba(2, 0, 36, 0) 100%);

    svg.fa-angle-down {
      transform: rotate(0deg);
      -webkit-transition: all .35s ease-in;
      -moz-transition: all .35s ease-in;
      -o-transition: all .35s ease-in;
      transition: all .35s ease-in;
    }

    +.expand-tab {
      -webkit-transition: height .35s ease-in;
      -moz-transition: height .35s ease-in;
      -o-transition: height .35s ease-in;
      transition: height .35s ease-in;
    }

    span.light {
      background-color: var(--accented);
      left: 0;
      height: 100%;
      width: 3px;
      top: 0;
      border-radius: 0;
    }
  }
}
</style>
